import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin, Table } from "antd";
import "../LogsChart/logsCharts.css";
import {
  getScraping,
  startProcessingForDebugging,
} from "../../../Redux/Actions/transferDataAction";

const Scraping = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { debuggingInfo, loading } = state;
  // const tableRef = useRef(null);
  let disable = false;

  const scrapeData = {
    startDate: "2024-10-17T11:07:49.142026059Z",
    endDate: "2024-10-17T11:07:50.021362635Z",
    status: "DONE",
    resultPath: "https://assets.stg.dospace.tech/test_results/automation",
    results: [
      {
        id: 0,
        productId: 70,
        productType: "FLOORTILE",
        productName: "Makoto Umi Terracotta",
        vendor: "Bedrosians",
        startDate: "2024-10-17T11:07:49.142669740Z",
        endDate: "2024-10-17T11:07:49.142678110Z",
        status: "IN_PROGRESS",
        duplicatedByName: null,
        duplicatedByImage: false,
        productURL:
          "https://mercier-wood-flooring.com/us/en/hardwood-flooring/red-oak-balance-source",
        isURLEmpty: false,
        isURLValid: true,
        isURLEncoded: true,
        isURLMatch: true,
        image: {
          scrapped: false,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: true,
          scrappedValue: {
            amount: 23.45,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 12.34,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: true,
          scrappedValue: true,
          dbValue: true,
        },
        sku: {
          scrapped: true,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
      {
        id: 1,
        productId: 72,
        productType: "FLOORTILE",
        productName: "Makoto Umi Terracotta",
        vendor: "Bedrosians",
        startDate: "2024-10-17T12:38:57.566642828Z",
        endDate: "2024-10-17T12:38:57.566651592Z",
        status: "IN_PROGRESS",
        duplicatedByName: null,
        duplicatedByImage: true,
        productURL:
          "https://mercier-wood-flooring.com/us/en/hardwood-flooring/red-oak-balance-source",
        isURLEmpty: true,
        isURLValid: false,
        isURLEncoded: false,
        isURLMatch: false,
        image: {
          scrapped: true,
          scrappedValue: null,
          dbValue: "https://public.dospace.com/test_results/1.jpg",
          scrappedHeight: null,
          scrappedWidth: null,
          similarity: 0,
        },
        price: {
          scrapped: false,
          scrappedValue: {
            amount: 23.45,
            currency: "USD",
            unit: "PEACE",
          },
          dbValue: {
            amount: 12.34,
            currency: "USD",
            unit: "PEACE",
          },
        },
        inStock: {
          scrapped: false,
          scrappedValue: false,
          dbValue: false,
        },
        sku: {
          scrapped: false,
          scrappedValue: "TEST_SKU",
          dbValue: "DECMAKUMTHEX10M",
        },
      },
    ],
  };
  const scrapeColumn = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Product ID",
      dataIndex: "productId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "In Stock",
      dataIndex: "inStock",
      render: (record) => {
        console.log(record.scrapped);
        return `${record.scrapped}`;
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (record) => {
        console.log(record.scrapped);
        return `${record.scrapped}`;
      },
    },
    {
      title: "Url",
      dataIndex: "",
      render: (record) => {
        console.log(record.isURLMatch);
        return `${record.isURLMatch}`;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (record) => {
        console.log(record.scrapped);
        return `${record.scrapped}`;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      render: (record) => {
        console.log(record.scrapped);
        return `${record.scrapped}`;
      },
    },
  ];

  debuggingInfo.forEach((item) =>
    !item.status || item.status === "In Progress" ? (disable = true) : null
  );

  // useEffect(() => {
  //   dispatch(
  //     set_min_width_header(
  //       tableRef?.current?.children[0].children[0].children[0].children[0]
  //         .children[0].children[0]?.offsetWidth
  //     )
  //   );
  // }, [tableRef, dispatch]);
  useEffect(() => {
    dispatch(getScraping());
  }, [dispatch]);
  return (
    <div className="logs">
      {loading.debuggingInfo ? (
        <div className={"chartLoading"}>
          <Spin size={"large"} />
        </div>
      ) : (
        <>
          <Button
            style={{ marginTop: "10px" }}
            disabled={disable}
            onClick={() => dispatch(startProcessingForDebugging())}
          >
            Processing
          </Button>
          <Table
            rowClassName={(record, index) => {
              if (record.isURLEmpty) {
                return "error-row";
              }
              return index % 2 === 0 ? "table-row-light" : "table-row-dark";
            }}
            // ref={tableRef}
            size={"small"}
            className={"globalTable"}
            rowKey={"commit"}
            // loading={loading}
            dataSource={scrapeData.results}
            columns={scrapeColumn}
            bordered
          />
        </>
      )}
    </div>
  );
};

export default Scraping;
