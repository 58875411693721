import {
  SET_ACTIVE_TAB_IN_LOGS,
  SET_CHART_FILTER,
  SET_COUNTRIES,
  SET_FILTER_PARAMS,
  SET_FORMS,
  SET_IS_MODAL,
  SET_LOADING,
  SET_MATERIAL_ID_FOR_PROCESSING,
  SET_MIN_WIDTH_HEADER,
  SET_REQUESTS,
  SET_RESPONSE_NOTIFICATION,
  SET_SPACE_INFO,
  SET_TABLES_PAGE_INFO,
  SET_VIEW_ALL_PROCESS,
  SET_IN_PROGRESS_MATERIALS,
  SET_BACKEND_ERRORS,
  SET_AFFILIATE_LINKS,
  SET_AFFILIATE_INCOMES,
  SET_DEBUGGING_INFO,
  SET_SERVER_IS_DOWN,
  SET_TESTING_MODE,
  SET_IMPRESSIONS,
  SET_CLICKS,
} from "../Actions/commonAction";
import { SET_IN_PROGRESS_WALLS, SET_WALLS } from "../Actions/wall";
import { SET_FLOORS, SET_IN_PROGRESS_FLOORS } from "../Actions/floor";
import { SET_CHECKED_VENDOR_PAUSED, SET_VENDORS } from "../Actions/vendor";
import { commonFilter, defaultPaginationInfo } from "../../config";
import { LOG_OUT, SET_AUTH_INFO } from "../Actions/auth";

const initialState = {
  auth_info: localStorage.getItem("auth_info")
    ? JSON.parse(localStorage.getItem("auth_info"))
    : "",
  loading: {
    vendors: false,
    walls: false,
    floors: false,
    rugs: false,
    wallDecors: false,
    floorDecors: false,
    tableDecors: false,
    forms: false,
    affiliateLinks: false,
    affiliateIncomes: false,
    requests: false,
    stillPhotos: false,
    backendErrors: false,
    debuggingInfo: false,
    testingMode: false,
    impressions: false,
    clicks: false,
  },
  responseNotification: null,
  only_one_wall_loading: false,
  only_one_floor_loading: false,
  is_modal: null,
  filterParams: {
    vendors: commonFilter("vendors", false),
    walls: commonFilter("walls", false),
    floors: commonFilter("floors", false),
    forms: commonFilter("forms", false),
    rugs: commonFilter("rugs", false),
    wallDecors: commonFilter("wallDecors", false),
    floorDecors: commonFilter("floorDecors", false),
    tableDecors: commonFilter("tableDecors", false),
  },
  inProgressMaterialLoading: {
    walls: false,
    floors: false,
    rugs: false,
    decors: false,
  },
  checked_vendor_paused: null,
  vendors: [],
  walls: [],
  floors: [],
  forms: [],
  backendErrors: [],
  debuggingInfo: [],
  requests: [],
  affiliateLinks: [],
  affiliateIncomes: [],
  countries: [],
  impressions: [],
  clicks: [],
  space_info: null,
  view_impressions_and_clicks_info: false,
  material_id_for_processing: false,
  active_tab_in_logs: "table",
  chart_filter: 1,
  tablesPageInfo: sessionStorage.getItem("tablesPageInfo")
    ? JSON.parse(sessionStorage.getItem("tablesPageInfo"))
    : {
        walls: defaultPaginationInfo,
        floors: defaultPaginationInfo,
        vendors: defaultPaginationInfo,
        forms: defaultPaginationInfo,
        logs: defaultPaginationInfo,
        rugs: defaultPaginationInfo,
        wallDecors: defaultPaginationInfo,
        floorDecors: defaultPaginationInfo,
        tableDecors: defaultPaginationInfo,
      },
  min_width_header: 0,
  serverIsDown: false,
  testingMode: false,
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_INFO:
      return {
        ...state,
        auth_info: localStorage.getItem("auth_info")
          ? JSON.parse(localStorage.getItem("auth_info"))
          : "",
      };
    case LOG_OUT:
      return {
        ...state,
        auth_info: "",
        walls: [],
        floors: [],
        forms: [],
        vendors: [],
        filterParams: {
          walls: commonFilter("walls", true),
          floors: commonFilter("floors", true),
          forms: commonFilter("forms", true),
          rugs: commonFilter("rugs", true),
          wallDecors: commonFilter("wallDecors", true),
          floorDecors: commonFilter("floorDecors", true),
          tableDecors: commonFilter("tableDecors", true),
        },
      };

    case SET_VENDORS:
      return { ...state, vendors: action.payload };
    case SET_WALLS:
      return { ...state, walls: action.payload };
    case SET_FLOORS:
      return { ...state, floors: action.payload };
    case SET_FORMS:
      return { ...state, forms: action.payload };
    case SET_AFFILIATE_LINKS:
      return { ...state, affiliateLinks: action.payload };
    case SET_AFFILIATE_INCOMES:
      return { ...state, affiliateIncomes: action.payload };
    case SET_REQUESTS:
      return { ...state, requests: action.payload };
    case SET_BACKEND_ERRORS:
      return { ...state, backendErrors: action.payload };
    case SET_DEBUGGING_INFO:
      return { ...state, debuggingInfo: action.payload };
    case SET_LOADING:
      state.loading[action.payload.type] = action.payload.value;
      return { ...state };
    case SET_RESPONSE_NOTIFICATION:
      return { ...state, responseNotification: action.payload };
    case SET_IN_PROGRESS_MATERIALS:
      state.inProgressMaterialLoading[action.payload.type] =
        action.payload.value;
      return {
        ...state,
        inProgressMaterialLoading: { ...state.inProgressMaterialLoading },
      };
    case SET_IS_MODAL:
      return { ...state, is_modal: action.payload };
    case SET_FILTER_PARAMS:
      return { ...state, filterParams: action.payload };
    case SET_IN_PROGRESS_WALLS:
      return { ...state, in_progress_walls: action.payload };
    case SET_IN_PROGRESS_FLOORS:
      return { ...state, in_progress_floors: action.payload };
    case SET_CHECKED_VENDOR_PAUSED:
      return { ...state, checked_vendor_paused: !state.checked_vendor_paused };
    case SET_SPACE_INFO:
      return { ...state, space_info: action.payload };
    case SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_IMPRESSIONS:
      return { ...state, impressions: action.payload };
    case SET_CLICKS:
      return { ...state, clicks: action.payload };
    case SET_VIEW_ALL_PROCESS:
      return { ...state, view_all_process: action.payload };
    case SET_MATERIAL_ID_FOR_PROCESSING:
      return { ...state, material_id_for_processing: action.payload };
    case SET_ACTIVE_TAB_IN_LOGS:
      return { ...state, active_tab_in_logs: action.payload };
    case SET_CHART_FILTER:
      return { ...state, chart_filter: action.payload };
    case SET_TABLES_PAGE_INFO:
      return { ...state, tablesPageInfo: action.payload };
    case SET_MIN_WIDTH_HEADER:
      return { ...state, min_width_header: action.payload };
    case SET_SERVER_IS_DOWN:
      return { ...state, serverIsDown: action.payload };
    case SET_TESTING_MODE:
      return { ...state, testingMode: action.payload };
    default:
      return state;
  }
};
class Service {
  // protected server_url = process.env.REACT_APP_SERVER_URL ;
  // protected server_url_endpoint = process.env
  //   .REACT_APP_SERVER_URL_ENDPOINT ;
  app_domain = process.env.REACT_APP_DOMAIN;
  server_domain = process.env.REACT_APP_SERVER_DOMAIN;
  server_domain_endpoint = process.env.REACT_APP_SERVER_DOMAIN_ENDPOINT;
  log_in = `${this.server_domain_endpoint}/login`;
  walls_api = `${this.server_domain_endpoint}/walls`;
  floors_api = `${this.server_domain_endpoint}/floors`;
  vendors = `${this.server_domain_endpoint}/vendors`;
  forms = `${this.server_domain_endpoint}/filter-forms`;
  affiliates_links = `${this.server_domain_endpoint}/admin/affiliates`;
  affiliates_incomes = `${this.server_domain_endpoint}/admin/affiliates/income`;
  add_floors_tile = `${this.server_domain_endpoint}/floors-material`;
  filter_floor_tiles = `${this.server_domain_endpoint}/filter-floor-tiles`;
  filter_wall_colors = `${this.server_domain_endpoint}/filter-wall-colors`;
  preprocess_for_new_walls = `${this.server_domain_endpoint}/preprocess-walls`;
  preprocess_for_new_floors = `${this.server_domain_endpoint}/preprocess-floors`;
  wall_in_preprocess = `${this.server_domain_endpoint}/wall-in-preprocess`;
  floor_in_preprocess = `${this.server_domain_endpoint}/floor-in-preprocess`;
  space = `${this.server_domain_endpoint}/space`;
  countries_for_filter = `${this.server_domain_endpoint}/countries`;
  add_credit_card = `${this.server_domain_endpoint}/save-credit-card`;
  delete_card = `${this.server_domain_endpoint}/credit-cards`;
  do_charge = `${this.server_domain_endpoint}/charge`;
  all_requests = `${this.server_domain_endpoint}/requests`;
  allBackendErrors = `${this.server_domain_endpoint}/errors`;
  zoom_floor = `${this.server_domain_endpoint}/transform-floor`;
  debugging_history = `${this.server_domain_endpoint}/admin/test/integration`;
  server_restart = `${this.server_domain_endpoint}/restart`;
  testing_mode = `${this.server_domain_endpoint}/admin/state/testing-mode`;
  impression = `${this.server_domain_endpoint}/impressions`;
  click = `${this.server_domain_endpoint}/clicks`;

  get_server_domain(with_api) {
    return with_api ? this.server_domain_endpoint : this.server_domain;
  }
  login() {
    return this.log_in;
  }
  walls() {
    return this.walls_api;
  }
  floors() {
    return this.floors_api;
  }
  get_vendors() {
    return this.vendors;
  }
  get_forms() {
    return this.forms;
  }
  get_affiliates_links() {
    return this.affiliates_links;
  }
  get_affiliates_incomes() {
    return this.affiliates_incomes;
  }
  add_floor_tile() {
    return this.add_floors_tile;
  }
  get_filter_floor_tiles() {
    return this.filter_floor_tiles;
  }
  get_filter_wall_colors() {
    return this.filter_wall_colors;
  }
  preprocess_walls() {
    return this.preprocess_for_new_walls;
  }
  preprocess_floors() {
    return this.preprocess_for_new_floors;
  }
  walls_in_preprocess() {
    return this.wall_in_preprocess;
  }
  floors_in_preprocess() {
    return this.floor_in_preprocess;
  }
  spaces_info() {
    return this.space;
  }
  countries() {
    return this.countries_for_filter;
  }
  save_credit_card() {
    return this.add_credit_card;
  }
  delete_credit_card() {
    return this.delete_card;
  }
  charge() {
    return this.do_charge;
  }
  requests() {
    return this.all_requests;
  }
  backendErrors() {
    return this.allBackendErrors;
  }
  transform_floor() {
    return this.zoom_floor;
  }
  debuggingHistory() {
    return this.debugging_history;
  }
  restartServer() {
    return this.server_restart;
  }
  testingMode() {
    return this.testing_mode;
  }
  impressions() {
    return this.impression;
  }
  clicks() {
    return this.click;
  }
}

export default new Service();
