import React, { useEffect, useRef } from "react";
import { Button, Spin, Switch, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  set_is_modal,
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { calculateTotal, showColumns } from "../../config";
import {
  ColumnWidthOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { get_vendors } from "../../Redux/Actions/vendor";
import {
  getDecors,
  pause_decor,
  prepoccessDecor,
} from "../../Redux/Actions/decor";
import ImageAndImageLoading from "../imageAndImageLoading/imageAndImageLoading";

const FloorDecors = () => {
  const state = useSelector((state) => state.common);
  const floorDecors = useSelector((state) => state.decors.floorDecors);
  const {
    vendors,
    loading,
    auth_info,
    inProgressMaterialLoading,
    tablesPageInfo,
  } = state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, floorDecors: e }));
  };
  let floorDecorsColumns = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
      forVendor: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      forVendor: true,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorID",
      render: (record) => {
        const vendor = vendors.find((vendor) => +vendor.key === record);
        return vendor ? vendor.name : "no vendor";
      },
      forVendor: false,
    },
    {
      title: "Material",
      dataIndex: "",
      render: (record) =>
        record.name !== "Blank" ? (
          <ImageAndImageLoading src={record.material} />
        ) : (
          ""
        ),
      forVendor: true,
    },
    {
      title: "Type",
      dataIndex: "typeSubType",
      forVendor: true,
      sorter: (a, b) => a.typeSubType.localeCompare(b.typeSubType),
    },
    {
      title: "Material Status",
      dataIndex: "",
      forVendor: false,
      render: (record) => {
        return (
          <>
            <Switch
              checked={record.paused}
              checkedChildren="Paused"
              unCheckedChildren="Live"
              onChange={() => {
                dispatch(pause_decor(record.key, "floor"));
              }}
            />
            <br />
            {record.impressionLimit}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Cost <br /> Total (${calculateTotal(floorDecors, "cost").toFixed(2)})
        </span>
      ),
      dataIndex: "cost",
      forVendor: true,
      sorter: (a, b) => b.cost - a.cost,
      render: (record) => `$${record.toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "",
      sorter: (a, b) => a.preprocessed - b.preprocessed,
      render: (record) => (
        <>
          {record.preprocessed ? (
            <span>Live</span>
          ) : +record.key === +inProgressMaterialLoading.decors ? (
            <Spin />
          ) : (
            <Button
              disabled={inProgressMaterialLoading.decors}
              onClick={() => dispatch(prepoccessDecor(record.key, "floor"))}
            >
              Push Live
            </Button>
          )}
        </>
      ),
      forVendor: false,
    },
    {
      title: (
        <span>
          Forms <br /> Total ({calculateTotal(floorDecors, "formIds")})
        </span>
      ),
      dataIndex: "",
      sorter: (a, b) => b.formIds?.length - a.formIds?.length,
      render: (record) => (
        <div className={"impressionsRow"}>
          <span>{record.formIds?.length}</span>
          {record.formIds?.length ? (
            <Button
              size={"small"}
              onClick={() =>
                dispatch(
                  set_is_modal({
                    open: {
                      formIds: record.formIds,
                      name: record.name,
                    },
                    type: "forms",
                  })
                )
              }
            >
              View
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
      forVendor: false,
    },
    {
      title: (
        <span>
          Width <br /> (inch)
        </span>
      ),
      dataIndex: "width",
      sorter: (a, b) => b.width - a.width,
      forVendor: true,
      render: (record) => record,
    },
    {
      title: (
        <span>
          Height <br /> (inch)
        </span>
      ),
      dataIndex: "height",
      sorter: (a, b) => b.height - a.height,
      forVendor: true,
      render: (record) => record,
    },
    {
      title: "Price",
      dataIndex: "price",
      forVendor: true,
      sorter: (a, b) => b.price?.amount - a.price?.amount,
      render: (record) =>
        `${record?.amount} ${record?.currency} - ${record?.unit}`,
    },
    {
      title: "Logo",
      dataIndex: "",
      render: (record) =>
        record.name !== "Blank" ? (
          <img
            src={record.logo}
            style={{ cursor: "pointer" }}
            onClick={() =>
              record.logo &&
              dispatch(set_is_modal({ open: record.logo, type: "view_photo" }))
            }
            alt="wall logo"
            width={30}
          />
        ) : (
          ""
        ),
      forVendor: false,
    },
    {
      title: "Actions",
      dataIndex: "",
      sorter: (a, b) => b.editable - a.editable,
      render: (record) => (
        <div className="actions">
          {record.preprocessed ? (
            <>
              {record.sphereUrl && (
                <Tooltip title="View Sphere">
                  <EyeOutlined
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      window.open(record.sphereUrl, "_blank");
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip title="Zoom">
                <ColumnWidthOutlined
                  onClick={() => {
                    dispatch(set_is_modal({ open: record, type: "zoom" }));
                  }}
                />
              </Tooltip>
            </>
          ) : (
            ""
          )}
          {record.editable &&
          +record.key !== +inProgressMaterialLoading.decors ? (
            <>
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={() => {
                    dispatch(set_is_modal({ open: record, type: "edit" }));
                    // dispatch(set_active_item_for_change(record));
                  }}
                />
              </Tooltip>
              {!auth_info.isVendor ? (
                <Tooltip title="Delete">
                  <DeleteOutlined
                    onClick={() => {
                      dispatch(set_is_modal({ open: record, type: "delete" }));
                      // dispatch(set_active_item_for_change(record));
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </>
          ) : (
            "Non Editable"
          )}
        </div>
      ),
      forVendor: true,
    },
  ];
  useEffect(() => {
    if (!floorDecors.length) {
      dispatch(getDecors("floor"));
    }
    if (!vendors.length && !auth_info.isVendor) {
      dispatch(get_vendors());
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.floorDecors}
        dataSource={floorDecors}
        columns={showColumns(floorDecorsColumns, vendors.length)}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.floorDecors}
      />
    </div>
  );
};

export default FloorDecors;
