import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  activeTab,
  changeFileName,
  optionForVendor,
  subTypeValue,
  tilesCount,
  validateMaterialCode,
} from "../../config";
import "./modal.css";
import { add_vendor } from "../../Redux/Actions/vendor";
import { add_floor } from "../../Redux/Actions/floor";
import { add_wall } from "../../Redux/Actions/wall";
import { useLocation } from "react-router-dom";
import { addRug } from "../../Redux/Actions/rugs";
import { addDecor } from "../../Redux/Actions/decor";
import VendorContent from "./content/vendors/vendorContent";
import WallContent from "./content/walls/wallContent";
import FloorContent from "./content/floors/floorContent";
import RugContent from "./content/rugs/rugContent";
import DecorContent from "./content/decors/decorContent";
import { generateAffiliateLinks } from "../../Redux/Actions/transferDataAction";

const AddModal = ({ open, setOpen }) => {
  const location = useLocation();
  const state = useSelector((state) => state.common);
  const { vendors, auth_info, floors } = state;
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [materials, setMaterials] = useState([null]);
  const [materialFiles, setMaterialFiles] = useState([null]);
  const [validateText, setValidateText] = useState("");
  const [costPerView, setCostPerView] = useState([0]);
  const [floorGlobalType, setFloorGlobalType] = useState(null);
  const [name, setName] = useState("");
  let optionsSelectVendor = [];
  vendors.forEach((item) => {
    if (!item.paused) {
      optionsSelectVendor = optionForVendor(
        item.type,
        activeTab(location.pathname)
      );
    }
  });
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const onFinish = (value) => {
    if (location.pathname === "/vendors") {
      if (logoFile) {
        dispatch(
          add_vendor({
            ...value,
            logo: logoFile,
            costPerView: costPerViewList(costPerView),
            impressionLimit: costPerViewList(costPerView).length,
          })
        );
        handleOk();
      } else {
        setValidateText("fill in all inputs");
      }
    } else if (location.pathname === "/floors") {
      if (validateMaterialCode(floors, value.code.replace(/\s/g, ""))) {
        if (materialFiles[0] !== null) {
          let x = true;
          materialFiles.forEach((item) => {
            if (item && !item.length) {
              x = false;
              setValidateText("fill in all inputs");
            } else {
              if (
                materialFiles.length < 6 &&
                value.sewingPattern === "staggered"
              ) {
                x = false;
                setValidateText("minimum material count 5");
              }
            }
          });
          if (x) {
            dispatch(
              add_floor({
                name: value.name,
                code: value.code,
                url: value.url,
                vendorID: value.vendorID,
                sewingPattern: value.sewingPattern,
                type: value.type
                  .substring(value.type.indexOf("_") + 1, value.type.length)
                  .toLowerCase(),
                material:
                  materialFiles[materialFiles.length - 1] === null
                    ? materialFiles
                    : [...materialFiles, null],
              })
            );
            handleOk();
          }
        } else {
          setValidateText("fill in all inputs");
        }
      } else {
        setValidateText("Code Already Exists");
      }
    } else if (location.pathname === "/walls") {
      dispatch(
        add_wall({
          name: value.name,
          url: value.url,
          color: value.color.hex,
          vendorID: value.vendorID,
          skuNumber: value.skuNumber,
        })
      );
      handleOk();
    } else if (location.pathname === "/rugs") {
      if (logoFile) {
        dispatch(
          addRug({ ...value, price: value.price.amount, material: logoFile })
        );
        handleOk();
      } else {
        setValidateText("fill in all inputs");
      }
    } else if (location.pathname === "/wallDecors") {
      if (logoFile) {
        dispatch(
          addDecor({
            ...value,
            price: value.price.amount,
            material: logoFile,
            type: "WALL",
            subType: subTypeValue(value.subType),
          })
        );
        handleOk();
      } else {
        setValidateText("fill in all inputs");
      }
    } else if (location.pathname === "/floorDecors") {
      if (logoFile) {
        dispatch(
          addDecor({
            ...value,
            price: value.price.amount,
            material: logoFile,
            type: "FLOOR",
            subType: subTypeValue(value.subType),
          })
        );
        handleOk();
      } else {
        setValidateText("fill in all inputs");
      }
    } else if (location.pathname === "/tableDecors") {
      if (logoFile) {
        dispatch(
          addDecor({
            ...value,
            price: value.price.amount,
            material: logoFile,
            type: "TABLE",
            subType: subTypeValue(value.subType),
          })
        );
        handleOk();
      } else {
        setValidateText("fill in all inputs");
      }
    } else if (location.pathname === "/affiliateLinks") {
      dispatch(generateAffiliateLinks(name));
      handleOk();
    }
  };
  const handler_sphere = (event, type, materialCount) => {
    if (event.target && event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      const renameFile = new File(
        [event.target.files[0]],
        `${type}-${changeFileName()}`
      );
      reader.addEventListener("load", () => {
        if (type === "material") {
          materialFiles[materialCount] = { length: "", file: renameFile };
          setMaterialFiles([...materialFiles]);
          materials[materialCount] = `${reader.result}`;
          setMaterials([...materials]);
        } else {
          setLogoFile(renameFile);
          setLogo(`${reader.result}`);
        }
      });
    }
  };
  const onChangeLength = (event, count) => {
    materialFiles[count].length = +event;
    setMaterialFiles([...materialFiles]);
  };
  const costPerViewList = (costPerView) => {
    let arr = [];
    costPerView.forEach((item) => {
      if (item) {
        arr.push(+item);
      }
    });
    return arr;
  };

  useEffect(() => {
    if (
      materialFiles[materialFiles.length - 1] !== null &&
      materialFiles.length < tilesCount
    ) {
      materialFiles.push(null);
      materials.push(null);
      setMaterialFiles([...materialFiles]);
      setMaterials([...materials]);
    }
  }, [materials, materialFiles]);

  const selectOnChange = (e, type) => {
    if (type !== "vendor") {
      if (e === "checkerboard" || e === "flipped-checkerboard") {
        setFloorGlobalType("carpet");
      } else {
        setFloorGlobalType("wood");
      }
    }
  };
  return (
    <div>
      <Modal
        title={`Add Modal for ${activeTab(location.pathname)}`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h2 style={{ color: "red" }}>{validateText}</h2>
          {location.pathname === "/vendors" ? (
            <VendorContent
              costPerView={costPerView}
              setCostPerView={setCostPerView}
              logo={logo}
              setLogo={setLogo}
              costPerViewList={costPerViewList}
              setLogoFile={setLogoFile}
              location={location}
              handler_sphere={handler_sphere}
              setName={setName}
              forAdd={true}
            />
          ) : location.pathname === "/walls" ? (
            <WallContent
              auth_info={auth_info}
              setName={setName}
              vendors={vendors}
              forAdd={true}
            />
          ) : location.pathname === "/floors" ? (
            <FloorContent
              floorGlobalType={floorGlobalType}
              materialFiles={materialFiles}
              vendors={vendors}
              onChangeLength={onChangeLength}
              setName={setName}
              auth_info={auth_info}
              selectOnChange={selectOnChange}
              name={name}
              materials={materials}
              setMaterialFiles={setMaterialFiles}
              handler_sphere={handler_sphere}
              setMaterials={setMaterials}
              forAdd={true}
              material={null}
              setMaterial={null}
              setMaterialFile={null}
            />
          ) : location.pathname === "/rugs" ? (
            <RugContent
              selectOnChange={selectOnChange}
              auth_info={auth_info}
              setName={setName}
              vendors={vendors}
              logo={logo}
              setLogo={setLogo}
              setLogoFile={setLogoFile}
              handler_sphere={handler_sphere}
              forAdd={true}
            />
          ) : location.pathname === "/wallDecors" ||
            location.pathname === "/floorDecors" ||
            location.pathname === "/tableDecors" ? (
            <DecorContent
              setName={setName}
              logo={logo}
              setLogo={setLogo}
              setLogoFile={setLogoFile}
              selectOnChange={selectOnChange}
              auth_info={auth_info}
              vendors={vendors}
              handler_sphere={handler_sphere}
              forAdd={true}
            />
          ) : location.pathname === "/affiliateLinks" ? (
            <>
              <p style={{ textAlign: "center", width: "100%" }}>
                Optional Parameter
              </p>
              <Form.Item
                label="Rate"
                name="rate"
                rules={[
                  {
                    pattern: /^(0(\.\d+)?|1(\.0+)?)$/,
                    message: "Please enter a number from 0 to 1",
                  },
                ]}
                // rules={[{ required: false, message: "Please input name!" }]}
              >
                <Input onChange={(e) => setName(e.target.value)} />
              </Form.Item>
            </>
          ) : (
            ""
          )}

          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default AddModal;
