import React, { useEffect } from "react";
import { Button, DatePicker, Select, Switch, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  set_active_tab_in_logs,
  set_chart_filter,
  set_is_modal,
  setFilterParams,
} from "../../Redux/Actions/commonAction";
import { set_in_progress_walls } from "../../Redux/Actions/wall";
import "../allTables/allTables.css";
import {
  activeTab,
  allRoutes,
  checkFilterRequests,
  checkVendorType,
  initialParamsForFilter,
  isFiltered,
  logsChartOptions,
  logsNavigation,
  vendorTypes,
} from "../../config";
import { CloseSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import AddModal from "../modals/addModal";
import EditModal from "../modals/editModal";
import DeleteModal from "../modals/deleteModal";
import ViewPhotoModal from "../modals/viewPhotoModal";
import FilterModal from "../modals/filterModal";
import ViewImpressionsAndClicksInfo from "../modals/viewImpressionsAndClicksInfo";
import ProcessModal from "../modals/processModal";
import ChargeModal from "../modals/chargeModal";
import AddCard from "../modals/addCard";
import ZoomModal from "../modals/zoomModal";
import ViewFormsModal from "../modals/viewFormsModal";
import HeaderButton from "../headerButton/headerButton";
import { log_out } from "../../Redux/Actions/auth";
import RecordsDetail from "../modals/recordsDetail";
import {
  editTestingMode,
  getTestingMode,
  restartServer,
} from "../../Redux/Actions/transferDataAction";
import ViewAlgoVersions from "../modals/viewAlgoVersions";

const Header = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { Title } = Typography;
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();

  const state = useSelector((state) => state.common);
  const {
    auth_info,
    only_one_wall_loading,
    only_one_floor_loading,
    is_modal,
    active_tab_in_logs,
    chart_filter,
    testingMode,
    filterParams,
    serverIsDown,
    loading,
  } = state;
  const deleteFilter = () => {
    filterParams[activeTab(location.pathname)] = { ...initialParamsForFilter };
    checkFilterRequests(dispatch, filterParams, location.pathname);
  };
  const params = filterParams[activeTab(location.pathname)];

  const filterOnChange = (e) => {
    filterParams[activeTab(location.pathname)]["startDate"] = e ? e[0] : null;
    filterParams[activeTab(location.pathname)]["endDate"] = e ? e[1] : null;
    checkFilterRequests(dispatch, filterParams, location.pathname);
  };
  useEffect(() => {
    if (
      active_tab_in_logs === "debuggingInfo" &&
      activeTab(location.pathname) === "logs"
    ) {
      dispatch(getTestingMode());
    }
  }, [active_tab_in_logs, dispatch]);
  return (
    <div
      className={"header"}
      // style={{ minWidth: min_width_header ? min_width_header : "640px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span className={"timeZone"}>UTC Time Zone</span>
        <div className={"categories"}>
          {allRoutes.map((item) => {
            if (
              ((auth_info.isVendor &&
                checkVendorType(item.path, auth_info.type)) ||
                !auth_info.isVendor) &&
              item.name !== "Auth"
            ) {
              return (
                <HeaderButton
                  key={item.id}
                  route={item.path}
                  text={item.name}
                />
              );
            } else {
              return "";
            }
          })}
        </div>
        <Button
          className={"logOut"}
          onClick={() => {
            dispatch(log_out());
            navigate("/auth");
          }}
          type={"text"}
        >
          <u>Log Out</u>
        </Button>
      </div>
      {/*<div>*/}

      {auth_info.isVendor ? (
        <div className={"headerVendorPanel"}>
          <div style={{ opacity: "0" }}>
            {auth_info.vendor?.stripeCreditCardID ? (
              ""
            ) : (
              <Button>Add Card</Button>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Title level={3}>{auth_info.vendor?.name}</Title>
            <img
              src={auth_info.vendor.logo}
              alt="logo"
              title={"View"}
              width={"40"}
              style={{ margin: "0.8rem 0 0 1rem", cursor: "pointer" }}
              onClick={() =>
                dispatch(
                  set_is_modal({
                    open: auth_info.vendor.logo,
                    type: "view_photo",
                  })
                )
              }
            />
          </div>
          <div
            style={{
              marginLeft: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {auth_info.vendor?.stripeCreditCardID ? (
              // <Button
              //   style={{ marginLeft: "50px", background: "#59D07F" }}
              //   type={"primary"}
              //   onClick={() => setOpenCardModal(true)}
              // >
              //   Delete Card
              // </Button>
              ""
            ) : (
              <Button
                style={{ background: "#59D07F" }}
                type={"primary"}
                onClick={() =>
                  dispatch(set_is_modal({ open: true, type: "add_card" }))
                }
              >
                Add Card
              </Button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {auth_info.isVendor ? (
        <p style={{ textAlign: "center" }}>
          Amount Used - ${auth_info?.vendor?.dueCost.toFixed(2)}
        </p>
      ) : (
        ""
      )}
      {location.pathname !== "/" && (
        <div style={{ width: "90%" }}>
          {location.pathname === "/vendors" && (
            <div className={"headerContentForVendor"}>
              <div
                className={"addButton"}
                onClick={() =>
                  dispatch(set_is_modal({ open: true, type: "add" }))
                }
                title={"Add " + location.pathname}
              >
                + New
              </div>
              <Select
                placeholder={<span>Filter by types</span>}
                mode={"multiple"}
                defaultValue={filterParams["vendors"]}
                options={vendorTypes}
                onChange={(e) => {
                  filterParams["vendors"] = e;
                  dispatch(setFilterParams({ ...filterParams }));
                }}
              />
            </div>
          )}

          {(location.pathname !== "/vendors" ||
            location.pathname !== "/logs") && (
            <div
              style={{
                justifyContent:
                  location.pathname === "/forms" ? "center" : "space-between",
              }}
              className={"tableHeaderButtons"}
            >
              {!auth_info.isVendor &&
              (location.pathname === "/walls" ||
                location.pathname === "/floors") ? (
                <Button
                  type={"primary"}
                  disabled={only_one_wall_loading || only_one_floor_loading}
                  onClick={() => {
                    dispatch(set_in_progress_walls(null));
                    dispatch(set_in_progress_walls(null));
                    dispatch(set_is_modal({ open: true, type: "process" }));
                  }}
                >
                  Preprocess for all new {activeTab(location.pathname)}
                </Button>
              ) : (
                ""
              )}
              {location.pathname !== "/forms" &&
              location.pathname !== "/vendors" &&
              location.pathname !== "/logs" &&
              location.pathname !== "/affiliateIncomes" ? (
                <div
                  className={"newFloorOrWall"}
                  onClick={() =>
                    dispatch(set_is_modal({ open: true, type: "add" }))
                  }
                  title={"Add " + activeTab(location.pathname)}
                >
                  + New
                  {location.pathname === "/affiliateLinks"
                    ? " Link"
                    : " Product"}
                </div>
              ) : (
                ""
              )}

              {!auth_info.isVendor &&
              location.pathname !== "/vendors" &&
              location.pathname !== "/logs" &&
              location.pathname !== "/affiliateLinks" &&
              location.pathname !== "/affiliateIncomes" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    type={isFiltered(params) ? "dashed" : "dashed"}
                    style={{
                      background: isFiltered(params) ? "#59D07F" : "white",
                    }}
                    onClick={() =>
                      dispatch(set_is_modal({ open: true, type: "filter" }))
                    }
                  >
                    FILTER
                  </Button>
                  {isFiltered(params) && (
                    <CloseSquareOutlined
                      style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                      onClick={deleteFilter}
                    />
                  )}
                </div>
              ) : auth_info.isVendor ? (
                <RangePicker
                  format={"YYYY/MM/DD"}
                  value={[
                    params?.startDate
                      ? dayjs(params?.startDate, "YYYY-MM-DD")
                      : null,
                    params?.endDate
                      ? dayjs(params?.endDate, "YYYY-MM-DD")
                      : null,
                  ]}
                  onChange={(e) => filterOnChange(e)}
                />
              ) : (
                ""
              )}
            </div>
          )}
          {location.pathname === "/logs" && !auth_info.isVendor ? (
            <>
              <Button
                style={{ marginRight: "200px" }}
                onClick={() => {
                  dispatch(restartServer());
                }}
                disabled={serverIsDown}
              >
                Restart Server
              </Button>
              <Switch
                checked={testingMode}
                disabled={loading.testingMode}
                checkedChildren="Testing Mode"
                unCheckedChildren="Testing Mode"
                onChange={() => {
                  dispatch(editTestingMode(!testingMode));
                }}
              />
            </>
          ) : (
            ""
          )}

          {location.pathname === "/logs" && !auth_info.isVendor ? (
            <div className={"chartHeader"}>
              {active_tab_in_logs === "charts" ? (
                <Select
                  value={chart_filter}
                  options={logsChartOptions}
                  onChange={(e) => dispatch(set_chart_filter(e))}
                />
              ) : (
                ""
              )}
              {logsNavigation.map((item) => (
                <Button
                  key={item.id}
                  type={
                    active_tab_in_logs === item.activeTab
                      ? "primary"
                      : "default"
                  }
                  onClick={() =>
                    dispatch(set_active_tab_in_logs(item.activeTab))
                  }
                >
                  {item.name}
                </Button>
              ))}
            </div>
          ) : (
            ""
          )}
          <hr />
        </div>
      )}

      {is_modal && is_modal.type === "add" && (
        <AddModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "edit" && (
        <EditModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "delete" && (
        <DeleteModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "view_photo" && (
        <ViewPhotoModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "filter" && (
        <FilterModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "impressions_and_clicks" && (
        <ViewImpressionsAndClicksInfo
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "process" && (
        <ProcessModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "charge" && (
        <ChargeModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "add_card" && (
        <AddCard
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "zoom" && (
        <ZoomModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "forms" && (
        <ViewFormsModal
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "recordsDetail" && (
        <RecordsDetail
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
      {is_modal && is_modal.type === "viewAlgoVersions" && (
        <ViewAlgoVersions
          open={is_modal.open}
          setOpen={() => dispatch(set_is_modal(null))}
        />
      )}
    </div>
  );
};

export default Header;
